<template>
  <div class="card text-center p-3 shadow-sm">
    <p class="text-gray-500 mb-0">
      {{ title }}
    </p>
    <p class="h2 text-black font-normal">
      {{ value }}
    </p>
  </div>
</template>

<script>
export default {
  props: ["title", "value"],
};
</script>

<style>
.text-gray-500 {
  color: #76819b;
}
</style>