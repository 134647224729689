<template>
  <div class="pb-5 pl-3 pr-3">
    <div>
      <div class="pb-3 pt-3 d-flex align-items-end">
        <b-dropdown
          id="dropdown-1"
          :text="selectedEra"
          variant="outline-gray"
          boundary="outline"
          class="bg-white border rounded-lg shadow-sm"
        >
          <b-dropdown-item @click="selectedEra = 'Last Month'"
            >Last Month</b-dropdown-item
          >
          <b-dropdown-item @click="selectedEra = 'Last 3 Months'"
            >Last 3 Months</b-dropdown-item
          >
          <b-dropdown-item @click="selectedEra = 'Last 9 Months'"
            >Last 9 Months</b-dropdown-item
          >
        </b-dropdown>
        <b-dropdown
          id="dropdown-1"
          :text="selectedDays"
          variant="outline-gray"
          boundary="outline"
          class="bg-white border rounded-lg shadow-sm ml-3"
        >
          <b-dropdown-item @click="selectedDays = 'Last Week'"
            >Last Week</b-dropdown-item
          >
          <b-dropdown-item @click="selectedDays = 'Last 3 Days'"
            >Last 3 Days</b-dropdown-item
          >
          <b-dropdown-item @click="selectedDays = 'Last 5 Days'"
            >Last 5 Days</b-dropdown-item
          >
        </b-dropdown>
        <div class="pl-3">
          <p class="mb-0 text-black">From</p>
          <Datepicker :date="fromDate" @onDateSelect="onFromSelect" />
        </div>
        <div class="pl-3">
          <p class="mb-0 text-black">To</p>
          <Datepicker @onDateSelect="onToSelect" />
        </div>
        <b-button variant="primary" class="ml-3" @click="Filter()"
          >Filter</b-button
        >
      </div>
    </div>
    <div class="card p-3 shadow-sm rounded">
      <div class="">
        <Chart
          :reports="reportsGraph"
          :chartOptions="chartOptions"
          :series="series"
        />
      </div>
    </div>
    <div class="d-flex pt-5">
      <Card
        class="w-100"
        title="Average people per hour"
        :value="averageDailyDrinks.item1"
      />
      <Card
        class="w-100 ml-3 mr-3"
        title="Total people Out per day"
        :value="averageDailyDrinks.item2"
      />
      <Card
        class="w-100"
        title="Total people In per day"
        :value="averageDailyDrinks.item3"
      />
    </div>
    <div class="d-flex">
      <div class="pt-3">
      </div>
    </div>
    <div class="card p-3 shadow-sm rounded mt-3">
      <Table
        :data="reports"
        :brands="GetAllBrands"
        :products="GetAllProducts"
      />
    </div>
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
// import { xray } from "../../config/pluginInit";
import profileInformation from "components/settings/profile.vue";
import vaccinationInformation from "components/settings/vaccination.vue";
import vaccinecard from "components/settings/vaccinecard.vue";
import tests from "components/settings/tests.vue";
import Chart from "../../components/dashboard/chart.vue";
import Table from "../../components/reports/table-people.vue";
import Datepicker from "../../components/reusables/datepicker.vue";
import Card from "../../components/dashboard/card.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ProfileEdit",
  components: {
    profileInformation,
    vaccinationInformation,
    vaccinecard,
    tests,
    Chart,
    Datepicker,
    Table,
    Card,
  },
  watch: {
    selectedProduct(newVal) {
      this.GetReports();
      this.GetReportsGraph();
    },
    selectedBrand(newVal) {
      this.GetReports();
      this.GetReportsGraph();
    },
    selectedEra(newVal) {
      if (newVal === "Last Month") {
        this.fromDate = new Date(
          new Date().valueOf() - 1000 * 60 * 60 * 24 * 30
        );
      } else if (newVal === "Last 3 Months") {
        this.fromDate = new Date(
          new Date().valueOf() - 1000 * 60 * 60 * 24 * 30 * 3
        );
      } else {
        this.fromDate = new Date(
          new Date().valueOf() - 1000 * 60 * 60 * 24 * 30 * 9
        );
      }
      console.log(moment(this.fromDate, "MM-DD-YYYY").format("MM-DD-YYYY"));
    },
    selectedDays(newVal) {
      if (newVal === "Last Week") {
        this.fromDate = new Date(
          new Date().valueOf() - 1000 * 60 * 60 * 24 * 7
        );
      } else if (newVal === "Last 3 Days") {
        this.fromDate = new Date(
          new Date().valueOf() - 1000 * 60 * 60 * 24 * 3
        );
      } else {
        this.fromDate = new Date(
          new Date().valueOf() - 1000 * 60 * 60 * 24 * 5
        );
      }
    },
  },
  mounted() {
    // xray.index();
    // this.SetReports();
    this.Filter();
    this.SetAllBrands();
    this.SetAllProducts();
  },
  data() {
    return {
      selectedBrand: "",
      selectedProduct: "",
      fromDate: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24),
      toDate: new Date(),
      averageDrinks: 0,
      selectedEra: "Last Month",
      selectedDays: "Last Week",
      series: [
        {
          name: "series-xy",
          data: [],
        },
      ],
      chartOptions: {
        noData: {
          text: "Loading...",
        },
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "People per day",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
        },
      },
      reports: [],
      averageDailyDrinks: [],
      reportsGraph: [],
    };
  },
  computed: {
    // ...mapState(["reports"]),
    ...mapGetters(["GetAllBrands", "GetAllProducts"]),
  },
  methods: {
    ...mapActions(["SetReports", "SetAllBrands", "SetAllProducts"]),
    onFromSelect(val) {
      this.fromDate = val;
    },
    onToSelect(val) {
      this.toDate = val;
    },
    Filter() {
      this.GetReports();
      this.GetReportsGraph();
    },
    GetReportsGraph() {
      if (this.fromDate < this.toDate) {
        const id = localStorage.getItem("userId");
        console.log("id", id);
        axios
          .post(`api/Documents/GetDailyPeopleByCustomerId/`, {
            customerId: JSON.parse(id),
            dateFrom: moment(this.fromDate, "MM-DD-YYYY").format("MM-DD-YYYY"),
            dateTo: moment(this.toDate, "MM-DD-YYYY").format("MM-DD-YYYY"),
            products: this.selectedProduct,
            brand: this.selectedBrand,
          })
          .then((response) => {
            this.reportsGraph = response.data;
            const cat = this.reportsGraph.map((val) => {
              return val.amount;
            });
            const ser = this.reportsGraph.map((val) => {
              return val.date;
            });

            const t_amount = cat.reduce(function (acc, val) {
              return acc + val;
            }, 0);
            this.averageDrinks = t_amount / cat.length;
            this.chartOptions = { xaxis: { categories: ser } };
            this.series = [
              {
                name: "amount",
                data: cat,
              },
            ];
          });
      } else {
        this.$bvToast.toast(`From date must be smaller then to date `, {
          title: "Alert",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }
    },
    GetReports() {
      if (this.fromDate < this.toDate) {
        const id = localStorage.getItem("userId");
        axios
          .post(`api/Documents/GetPeopleByCustomerId/`, {
            customerId: JSON.parse(id),
            dateFrom: moment(this.fromDate, "MM-DD-YYYY").format("MM-DD-YYYY"),
            dateTo: moment(this.toDate, "MM-DD-YYYY").format("MM-DD-YYYY"),
            products: this.selectedProduct,
            brand: this.selectedBrand,
          })
          .then((response) => {
            this.reports = response.data;
          });

        axios
          .post(`api/Documents/GetAverageDailyPeopleByCustomerId/`, {
            customerId: JSON.parse(id),
            dateFrom: moment(this.fromDate, "MM-DD-YYYY").format("MM-DD-YYYY"),
            dateTo: moment(this.toDate, "MM-DD-YYYY").format("MM-DD-YYYY"),
            products: this.selectedProduct,
            brand: this.selectedBrand,
          })
          .then((response) => {
            this.averageDailyDrinks = response.data;
          });
      } else {
        this.$bvToast.toast(`From date must be smaller then to date `, {
          title: "Alert",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }
    },
  },
};
</script>
