import axios from "axios";
const state ={

}
const getters={

}
const actions={
    SetReports({commit},e){
        // console.log("cal")
        axios.get('api/Documents/GetByCustomerId/76d108ef-643b-4e7f-a28a-2786bf3423ca')
    }
}
export default{
    actions
}