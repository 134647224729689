<template>
  <div>
    <p class="mt-3">Current Page: {{ currentPage }}</p>
     <b-table
      id="my-table"
      :items="data"
      :fields="fields" 
      :per-page="perPage"
      :current-page="currentPage"
      small
    >   
    <!-- Optional default data cell scoped slot -->
      <template #cell(Videos)="data">  
        <Videoplayer 
          @onClick="$bvModal.show(data.item.id)"
          :s_id="data.item.id"
          :src="data.item.name"
        />
      </template> 
      <template #cell(Edit)="row">
        <Edit
          :products="products"
          :brands="brands"
          :intialData="row.item"
          @onClick="$bvModal.show(`${row.item.id} edit`)"
          @save="(val) => saveChanges(row.item.id, val, row)"
          :s_id="`${row.item.id} edit`"
            />
      </template>
  </b-table>
     <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
    ></b-pagination> 
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Videoplayer from "../dashboard/videoplayer.vue";
import { mapActions } from "vuex";
import Edit from "../dashboard/edit.vue";
export default {
  props: ["data", "products", "brands"],
  data() {
    return {
      currentId: 0,      
      perPage: 20,
      currentPage: 1,
      fields: [
        // A virtual column that doesn't exist in items
        'Videos',  
        // A regular column
        {key: 'amount', label:'Amount'},
        // A regular column
        {key: 'createdAt', label: 'Date' },  // A column that needs custom formatting
        {key: 'isVerified', label: 'Verified'},
        {key: 'isFalsePositive', label: 'Is False Positive'},
        'Brand',
        'Product',
        'Edit',
      ],
    };
  },
  components: {
    Videoplayer,
    Edit,
  },
  mounted(){
  },
  computed: {
     rows() {
        return this.data.length
      }
    },
  methods: {
    ...mapActions(["UpdateReports"]),
    togleModal() {},
    Created(val) {
      return moment(val, "MM-DD-YYYY").format("MM-DD-YYYY");
    },
    saveChanges(id, data, previous) {
      previous.isFalsePositive = data.isFalsePositive;
      previous.isVerified = data.isVerified;
      previous.productId = data.productId;
      previous.brandId = data.brandId;
      previous.amount = data.amount;
      this.UpdateReports({ id: id, previous: previous });
      // axios.put(`api/Documents/${id}`, previous);
    },
  },
};
</script>

<style></style>
