<template>
  <div>
    <div>
      <button class="btn btn-primary" @click="modalShow = !modalShow">Edit</button>
      <b-modal :id="s_id" hide-footer v-model="modalShow">
        <div style="w-100">
          <b-form-spinbutton id="demo-sb" v-model="amount" min="1" ></b-form-spinbutton>
          <b-form-checkbox
            v-model="isVerified"
            name="checkbox-1"
            :value="true"
            :unchecked-value="false"
          >
            Drink is verified
          </b-form-checkbox>
          <b-form-checkbox
            v-model="isFalsePositive"
            name="checkbox-2"
            :value="true"
            :unchecked-value="false"
          >
            False Positive
          </b-form-checkbox>
          <p class="mb-0 text-black">Selet Type of drink</p>
          <b-form-select
            v-model="selectedType"
            :options="typeOptions"
            size="sm"
            class=""
          ></b-form-select>
          <p class="mb-0 text-black">Select brand of drink</p>
          <b-form-select
            v-model="selectedBrand"
            :options="brandOptions"
            size="sm"
            class=""
          ></b-form-select>
        </div>
        <div class="d-flex align-items-center">
          <button
            class="mt-3 btn btn-danger w-100"
            danger
            block
            @click="$bvModal.hide(s_id)"
          >
            Close Me
          </button>
          <button class="mt-3 btn btn-primary w-100 ml-2" @click="saveItems">
            Save
          </button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  props: ["t_id", "s_id", "src", "intialData", "brands", "products"],
  data() {
    return {
      modalShow:false,
      isFalsePositive: this.intialData.isFalsePositive,
      isVerified: this.intialData.isVerified,
      selectedType: this.intialData.product,
      selectedBrand: this.intialData.brand,
      amount: this.intialData.amount,
      typeOptions: [
        { value: null, text: "Please select an option" },
        // { value: "Beer", text: "Beer" },
        // { value: "Coke", text: "Coke" },
        // { value: "Martinis", text: "Martinis" },
        // { value: "Rum", text: "Rum" },
      ],
      brandOptions: [
        { value: null, text: "Please select an option" },
        // { value: "Beer", text: "Beer" },
        // { value: "Coke", text: "Coke" },
        // { value: "Martinis", text: "Martinis" },
        // { value: "Rum", text: "Rum" },
      ],
    };
  },
  components: {},
  mounted() {
    console.log("pro",this.products)
    this.brandOptions = this.brands.map((val) => {
      return { value: val.id, text: val.name };
    });
    this.typeOptions = this.products.map((val) => {
      return { value: val.id, text: val.name };
    });
  },
  methods: {
    clickEvent() {
      this.$emit("onClick");
    },
    saveItems() {
      this.modalShow=false
      this.$emit("save", {
        
        isFalsePositive: this.isFalsePositive,
        isVerified: this.isVerified,
        productId: this.selectedType,
        brandId:this.selectedBrand,
        amount:this.amount
      });
      
    },
  },
};
</script>

<style>
</style>