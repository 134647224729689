<template>
  <b-collapse
    tag="ul"
    :class="className"
    :visible="open"
    :id="idName"
    :accordion="accordianName"
  >
    <li>
      <!-- <router-link :to="item.link" v-if="!item.is_heading" :class="`iq-waves-effect ${activeLink(item) && item.children ? 'active' : activeLink(item) ? 'active' : ''}`" v-b-toggle="item.name"> -->
      <router-link to="/">
        <i class="ri-goblet-line"></i>
        <span>Drink Count</span>
      </router-link>
      <router-link to="/peopleCount">
         <i class="ri-group-fill" />
        <span>People Count</span>
      </router-link>
      <!-- <router-link to="/products">
        <img  src="@/assets/images/product.png" style="width:25px" class="mr-2"/>
        <span>Products</span>
      </router-link> -->
      <!-- <List v-if="item.children" :items="item.children" :open="item.link.name !== '' && activeLink(item) && item.children ? true : !!(item.link.name !== '' && activeLink(item))" :idName="item.name" :accordianName="`sidebar-accordion-${item.class_name}`" :className="`iq-submenu ${item.class_name}`" /> -->
    </li>
  </b-collapse>
</template>
<script>
import List from "./ListStyle1";
import { xray } from "../../../config/pluginInit";
export default {
  name: "List",
  props: {
    items: Array,
    className: { type: String, default: "iq-menu" },
    horizontal: Boolean,
    open: { type: Boolean, default: false },
    idName: { type: String, default: "sidebar" },
    accordianName: { type: String, default: "sidebar" },
  },
  components: {
    List,
  },
  mounted() {},
  methods: {
    activeLink(item) {
      return xray.getActiveLink(item, this.$route.name);
    },
  },
};
</script>
