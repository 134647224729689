<template>
  <div style="w-100 d-flex justify-content-center">
    <video ref="videoPlayer" class="video-js" style=""></video>
  </div>
</template>

<script>
import videojs from "video.js";

import "video.js/dist/video-js.css";
export default {
  props:['source'],
  name: "VideoPlayer",
  data() {
    return {
      player: null,
      options: {
        autoplay: true,
        controls: true,
        sources: [
          {
            src: this.source,
            type: "video/mp4",
          },
        ],
      },
    };
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.options, () => {
      this.player.log("onPlayerReady", this);
    });
    console.log(this.source)
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
<style >
.video-js{
    width: 100%;
}
.vjs-controls-enabled{
    width: 100%;
    height: 400px;
}
.vjs-workinghover{
    width: 100%;
} 
/* vjs-v6 vjs_video_2644-dimensions vjs-playing vjs-has-started vjs-user-inactive */
</style>