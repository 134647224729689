<template>
  <div>
    <button class="btn btn-primary" @click="modalShow = !modalShow">
      Edit
    </button>

    <b-modal v-model="modalShow">
      <p class="mb-0">Name</p>
      <b-form-input
        id="input-small"
        size="sm"
        placeholder="Enter brand name"
        v-model="name"
      ></b-form-input>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="primary" @click="trigerSave"> Save</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["brand"],
  data() {
    return {
      modalShow: false,
      name: this.brand,
    };
  },
  methods:{
    trigerSave(){
      this.modalShow=false
      this.$emit("save",this.name)
    }}
};
</script>