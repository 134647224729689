<template>
  <div>
    <b-container class="pb-6 pb-8 pt-2 pt-md-8 bg-gradient-success">
      <b-row no-gutters>
        <b-col>
          <p class="no-padding-margin heading">Vaccinations</p>
          <p class="no-padding-margin sub-title">
            Edit your Vaccination here (this will display on your profile)
          </p>
        </b-col>
        <b-col offset-xl="4" md="3" lg="3" xl="2" class="mt-3">
          <b-button
            pill
            block
            variant="primary"
            @click="$bvModal.show('bv-modal-education')"
            >Add Vaccination</b-button
          >
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="mt--7 pb-8">
      <b-row no-gutters> </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-table
            outlined
            responsive
            head-variant="light"
            :items="items"
            :fields="fields"
          >
           <template v-slot:table-busy>
                <div class='text-center text-success my-2'>
                  <b-spinner class='align-middle'></b-spinner>
                  <strong>Loading...</strong>
                </div>
            </template>
            <template v-slot:cell(date)='data'>
                <p>{{ data.item.date | formatDate }}</p>
            </template>
            <template v-slot:cell(actions)="row">
              <b-dropdown variant="white" no-caret style="margin-top: -7px;">
                <template v-slot:button-content>
                  <b-icon
                    style="font-size:100%"
                    icon="three-dots-vertical"
                  ></b-icon>
                </template>
                <b-dropdown-item class="dropdown" @click="remove(row.item)"
                  ><span style="color:#FF7F7F">Remove</span></b-dropdown-item
                >
                <b-dropdown-item
                  class="dropdown"
                  v-if="row.item.document != null"
                  target="self"
                  :href="row.item.document.name"
                  ><span style="color:#FF7F7F"
                    >Download Credentials</span
                  ></b-dropdown-item
                >
              </b-dropdown>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-modal
        id="bv-modal-education"
        ref="modal"
        title="Add Vaccination"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            label="Vaccination"
            label-for="name-input"
            invalid-feedback="Name is required"
            :state="nameState"
          >
           <b-form-select v-model="form.name" :options="options" :state="nameState" required></b-form-select> 
          </b-form-group> 
          <b-form-group
            label="Date Taken"
            label-for="textarea-lazy"
            :state="startYearState"
            invalid-feedback="Date Taken is required"
            class="mb-0"
          >
            <b-form-datepicker
              id="startYear-datepicker"
              v-model="form.date"
              :state="startYearState"
              required
              class="mb-2"
            ></b-form-datepicker>
          </b-form-group> 
        </form>
      </b-modal>
    </b-container>
  </div>
</template>

<script> 
import { mapActions, mapState } from "vuex";
import {
  BIcon,
  BIconEnvelope,
  BIconThreeDotsVertical,
  BIconChevronDown,
  BIconChevronUp
} from "bootstrap-vue";
import { required, numeric, email } from "vuelidate/lib/validators";
import axios from "axios";
export default {
  components: {
    BIcon,
    BIconEnvelope,
    BIconThreeDotsVertical,
    BIconChevronDown,
    BIconChevronUp 
  },
  data() {
    return {
      partnerobject: null,
      dropDownIconToggale: false,
      form: {
        name: null, 
        startYear: "", 
        documentId: ""
      },
      options: [
          { value: null, text: 'Please select an option' },
          { value: 'Moderna', text: 'Moderna' },
          { value: 'Pfizer', text: 'Pfizer' },  
          { value: 'Astrazeneca', text: 'Astrazeneca ' }, 
          { value: 'Johnson and Johnson', text: 'Johnson and Johnson' }, 
          { value: 'Novavax ', text: 'Novavax ' }, 
      ],
      loggedInUser: JSON.parse(localStorage.getItem("org")),
      orgEmail: JSON.parse(localStorage.getItem("organizationEmail")),
      OrganizationId: JSON.parse(localStorage.getItem("organizationId")),
      validations: {
        isEmail: false,
        GivenName: false,
        FamilyName: false,
        isValid: true
      },
      users: [
        {
          EmailAddress: "",
          GivenName: "",
          FamilyName: "",
          Role: "Partner",
          OrganizationId: JSON.parse(localStorage.getItem("organizationId")),
          validations: {
            isEmail: false,
            isEmailDuplicate: false,
            GivenName: false,
            FamilyName: false,
            isValid: true
          }
        }
      ],
      loading: false,
      nameState: null,
      degreeState: null,
      startYearState: null,
      endYearState: null,
      fields: [
        {
          key: "name",
          label: "Vaccine",
          thClass: "tbHeadingClass",
          tdClass: "tbBodyClass"
        }, 
        {
          key: "date",
          label: "Date Taken",
          thClass: "tbHeadingClass",
          tdClass: "tbBodyClass"
        }, 
        {
          key: "actions",
          label: "Actions"
        }
      ]
    };
  },
  validations: {},
  methods: { 
    ...mapActions("company", ["getCompany", "updateCompany"]),
    ...mapActions("partner", ["getPartner"]),
    remove(item) {
      return axios.delete("/portal/api/Vaccinations/" + item.id).then(response => {
        this.getCompany(JSON.parse(localStorage.getItem("organizationId")));
      });
    },
    checkFormValidity() {
      let valid = this.$refs.form.checkValidity();
      this.startYearState = this.form.startYear != ""; 
      this.nameState = valid; 

      if (valid && this.startYearState) {
        valid = true;
      } else {
        valid = false;
      }

      return valid;
    },
    resetModal() {
      this.form = {
        name: "", 
        date: "", 
      };
      this.nameState = null; 
      this.startYearState = null; 
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      var self = this;

      this.form.organizationId = JSON.parse(
        localStorage.getItem("actualOrgId")
      );
      return axios.post("/portal/api/Vaccinations/", this.form).then(response => {
        self.getCompany(JSON.parse(localStorage.getItem("organizationId")));
        // Hide the modal manually
        self.$bvModal.hide("bv-modal-education");
      });
    }
  },
  computed: {
    ...mapState({
      partnerStore: State => State.partner.partner
    }),
    ...mapState({
      store: state => state.company
    }),
    items() {
      if (this.store.company.vaccinations != null) {
        return this.store.company.vaccinations;
      } else {
        return [];
      }
    },
    popoverConfig() {
      return {
        html: true,
        title: () => {
          return "<b>User Role Details:</b>";
        },
        content: () => {
          return "Super Admin:<br> <em>All User previlages, plus the ability to administrate organization. </em> <br><br> Coordinator:<br> <em>All User previlages, plus the ability to manage appointments and reports for others. </em> <br><br>User:<br><em>Users can manage their own appointments and reports, and update their personal profile. </em>";
        }
      };
    }
  },
  mounted: function() {
    this.$ga.page("/portal/education");
    this.OrganizationId = JSON.parse(localStorage.getItem("organizationId"));
  }
};
</script>

<style scoped>
.no-padding-margin {
  padding: 0px !important;
  margin: 0px !important;
  padding-left: 0px !important;
}

.heading {
  color: #01151c;
  font-size: 30px;
  font-weight: bold;
}

.sub-title {
  color: #576367;
  font-size: 13px;
  font-weight: bold;
}
</style>
 