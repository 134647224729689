<template>
  <div>
    <button class="btn btn-primary" @click="modalShow = !modalShow">+ Add New</button>

    <b-modal v-model="modalShow" title="Add New Product">
      <p class="mb-0">Name</p>
      <b-form-input
        id="input-small"
        size="sm"
        :placeholder="`Enter Product name`"
        v-model="name"
      ></b-form-input>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="danger" @click="cancel()"> Cancel </b-button>
        <b-button size="sm" variant="primary" @click="trigerSave"> Save</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["placeholder"],
  data() {
    return {
      modalShow: false,
      name: "",
    };
  },
  methods: {
    ...mapActions(["SetAllProducts", "CreateProducts", "EditProducts"]),
    trigerSave() {
      var self = this;
      this.CreateProducts(this.name).then(function () {
        //do stuff after the method has been called
        //this is my promise async await
        self.modalShow = false;
        self.SetAllProducts();
      });
    },
  },
};
</script>
