<template>
  <b-container fluid>
    <div class="content col-md-12" style="margin-left:-2%;margin-bottom:30px">
      <div class="header">
        <h3 class="heading-font">Vaccine Card</h3>
        <p style="font-size:16px">Choose how you’ll be seen on bevcounter.</p>
      </div>
      <div class="col-md-8 col-sm-12 menu" style="padding-top:2%;margin-bottom:30px">
        <div class="card">
          <div>  
            <div class="row">
              <div class="container" style="padding-top:3%">
                <b-img
                    v-b-hover="handleHover" 
                    center
                    :src="dbImgURL"
                    blank-color="blue"
                    fluid
                    data-toggle="modal" 
                    v-b-modal.profile-org-card
                    @error="imgError"
                    ref="imageRef"
                  ></b-img>  
              </div>
            </div>
          </div>
          <cardImageCropModalOrg title="imageCropper" @update-org-card="updateCardImage($event)"></cardImageCropModalOrg> 
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
  import axios from 'axios' 
  import cardImageCropModalOrg from 'components/settings/organization-sub-components/card-image-crop-organization.vue' 
  import { BIcon, BIconChevronRight, VBHover, BIconPencil } from 'bootstrap-vue'
  import { mapActions, mapState } from 'vuex'
  export default { 
    components: {
      BIcon,
      BIconChevronRight, 
      cardImageCropModalOrg, 
    },
    data() {
      return {
        fullName: '',
        displayName: '',
        displayCountry: '',
        StuttieUrl: '',
        timezoneInfo: '',
        showTimezone: '', 
        organizationId: '',
        timeZoneoptions: [],
        partnerId: '',
        options: [{ value: null, text: 'Please select an Provider' }],
        selectedImgURL: require('../../assets/images/user/11.png'),
        selectedCardImgURL: require('../../assets/images/user/card.jpg'), 
        cardImgURL: '',
        isHovered: false,
        imageFound: false,
        cardimageFound: false,  
        genderselected: [], // Must be an array reference!
        genderoptions: [
          { text: 'Male', value: 'm' },
          { text: 'Female', value: 'f' } 
        ]
      }
    },
    methods: {
      onClick(evt) { 
        // React only to the events fired from the `input`
        if (evt.target.tagName === 'INPUT') {
          this.genderselected = [evt.target.value];
          var _company = { ...this.store.company };
          _company.gender = evt.target.value;
          this.updateCompany(_company);
        }
      }, 
      imgError() {
        this.$refs.imageRef.src = '/uploads/localhost/profile_pic.png';
      }, 
      updateCardImage(response) {
        this.imageFound = response;
        //update done successfully and image found
        if (response) {
          this.getCompany(this.organizationId).then(() => { 
            this.dbImgURL = "https://stuttie-files.s3.us-east-2.amazonaws.com/" + this.organizationId + '/' + this.store.company.card;
          });
        }
      }, 
      handleHover(hovered) {
        this.isHovered = hovered 
      },
      ...mapActions('company', [
        'getCompany',
        'updateCompany'
      ]),
      ...mapActions('partner', [
        'getPartner'
      ]),
      ...mapActions('partner', [
        'getPartners'
      ]),   
    },
    computed: {
      ...mapState({
        store: state => state.company
      }),
      ...mapState({
        partnerStore: State => State.partner.partner
      }),
      ...mapState({
        storePartners: state => state.partner.partners
      }),
      dbImgURL: function() {
        return this.organizationId != null && this.store != null
          ? "https://stuttie-files.s3.us-east-2.amazonaws.com/" + this.organizationId + '/' + this.store.company.card
          : selectedImgURL;
      }  
    },
    mounted: function () {  
      this.$ga.page('/portal/settings/profile');
      this.organizationId = JSON.parse(localStorage.getItem('organizationId'));  

      this.getPartner(JSON.parse(localStorage.getItem('userId'))).then(() => { 
        this.fullName = this.partnerStore.givenName + " " + this.partnerStore.familyName; 
        if (this.partnerStore.displayName == null) {
          this.displayName = this.partnerStore.givenName;
        } else {
          this.displayName = this.partnerStore.displayName;
        }
        this.StuttieUrl = "https://bevcounter.life/" + this.partnerStore.defaultRoomId;
         
        //checking if logo exist
        if (this.store.company.card == null) {
          this.imageFound = false;
        }
        else {
          this.imageFound = true; 
        }
      });
      
    },
    created: function () {

    },

  }
</script>

<style scoped>
  .iconDropdown {
    position: relative;
    float: right;
    margin-top: -30px;
    margin-right: 20px;
  }

  .heading-font {
    font-weight: bold;
    color: #01151C;
  }

  .fontDetails {
    font-weight: bold;
    color: #01151C;
  }

  .eachRow {
    padding-left: 5%;
    padding-top: 1%;
  }

  .icon {
    color: #01151C;
  }

  .menu :hover {
    cursor: pointer
  }

  .header {
    padding-left: 1%;
    padding-top: 1%;
  }
</style>
