<template>
  <div class="card p-3 mb-3 rounded">
    <div class="d-flex justify-content-end pb-3">
      <AddNew @save="(val)=>create(val)" placeholder="products"/>
    </div>
    <table class="table align-middle mb-0 bg-white">
      <thead class="bg-light">
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th class="text-right  pr-5">Update</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(val, index) in GetAllProducts">
          <tr :key="index">
            <td>
              <p class="fw-normal mb-1">{{ val.id }}</p>
            </td>
            <td>
              <p class="fw-normal mb-1">{{ val.name }}</p>
            </td>
            <td class="d-flex justify-content-end">
              <Edit @save="(name)=> EditProducts({name:name,id:val.id})" :brand="val.name" />
              <button class=" btn btn-danger ml-2" @click="()=>DeleteProducts({name:val.name,id:val.id})">Delete</button>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Edit from "../../components/products/edit.vue";
import AddNew from "../../components/products/addNew.vue"
export default {
  mounted() { 
    this.SetAllProducts()
  },
  components: {
    Edit,
    AddNew
  },
  methods: {
    ...mapActions(["SetAllProducts","CreateProducts","EditProducts","DeleteProducts"]),
   create(val){
      this.CreateProducts(val)
    }
  },
  computed: {
    ...mapGetters(["GetAllProducts"]),
  },
};
</script>

<style>
</style>