<template>
  <div>
    <div>
      <img
        id="show-btn"
        src="@/assets/play.png"
        alt=""
        style="width: 45px; height: 45px; cursor: pointer"
        class="rounded-circle"
        @click="clickEvent"
      />
      <b-modal :id="s_id" hide-footer> <div style="w-100">
        <Video :source="src"/>
       </div>
        <b-button class="mt-3" primary block @click="$bvModal.hide(s_id)"
          >Close Me</b-button
        >
      </b-modal>
    </div>
  </div>
</template>

<script>
import Video from "../reports/video.vue";
export default {
  props: ["t_id", "s_id","src"],
  components: {
    Video,
  },
  mounted() {
    console.log(this.src)
  },
  methods: {
    clickEvent() {
      this.$emit("onClick");
    },
  },
};
</script>

<style>
</style>