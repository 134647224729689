<template>
  <div id="chart">
    <apexchart
      type="line"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
 import moment from 'moment';
import VueApexCharts from "vue-apexcharts";
export default {
  props:['reports','series',"chartOptions"],
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
     
    };
  },
  mounted(){
    console.log(this.reports)
    this.reports.map((val)=>{
      console.log()
    })
  }
};
</script>

<style>
.dropdown-toggle {
  border-radius: 0%;
}
</style>