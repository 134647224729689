
<template>
  <div>
    <b-form-datepicker
      value-as-date
      v-model="value"
      locale="en"
      class="border-sm bg-white shadow-sm"
      boundary="0px"
    >
    </b-form-datepicker>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["date"],
  data() {
    return {
      value: "",
    };
  },

  watch: {
    date(newVal) {
      this.value = newVal;
    },
    value(newVAL) {
      console.log();
      this.$emit("onDateSelect", newVAL);
    },
  },
};
</script>
<style>
.form-control {
  border: 0px;
}
</style>