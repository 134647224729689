import { userService } from '../../_services'
import router from '../../router/index'
import Vue from 'vue'
const user = JSON.parse(localStorage.getItem('user'))
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null }

export const authentication = {
  namespaced: true,
  state: initialState,
  actions: {
    login ({ dispatch, commit }, { email, password }) {
      commit('loginRequest', { email })
      userService.login(email, password)
        .then(
          user => {
            commit('loginSuccess', user)
            router.push('/')
          },
          error => {
            commit('loginFailure', error)
            dispatch('alert/error', error, { root: true })
            Vue.swal({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong! Please check username and password.',
              footer: '<a href="">Why do I have this issue?</a>'
            })
          }
        )
    },
    logout ({ commit }) {
      userService.logout()
      commit('logout')
      router.push('/login')
    }
  },
  mutations: {
    loginRequest (state, user) {
      state.status = { loggingIn: true }
      state.user = user
    },
    loginSuccess (state, user) {
      state.status = { loggedIn: true }
      state.user = user
    },
    loginFailure (state) {
      state.status = {}
      state.user = null
    },
    logout (state) {
      state.status = {}
      state.user = null
    }
  }
}
