import axios from "axios"
const state={
    brands:[]
}
const getters={
    GetAllBrands:(state)=>{
       return state.brands
    }
}
const actions={
    SetAllBrands({commit},e){
        var id = localStorage.getItem("organizationId");
        axios.get(`api/brands/`);
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    // mutations 
  }