import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import Setting from "./Setting/index";
import company from "./modules/Company";
import partner from "./modules/Partner";
import admins from "./modules/Admin";
import meeting from "./modules/Meetings";
import onboarding from "./modules/onBoarding";
import { alert } from "./modules/alert.module";
import { authentication } from "./modules/authentication";
import { users } from "./modules/users.module";
import posts from "./modules/Posts";
import alerts from "./modules/Alert";
import messages from "./modules/Message";
import chat from "./modules/Chat";
import course from "./modules/Course";
import school from "./modules/School";
import job from "./modules/Job";
import friend from "./modules/Friend";
import reports from "./modules/reports";
import brands from "./brands";
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    Setting,
    company,
    partner,
    alert,
    authentication,
    users,
    admins,
    meeting,
    onboarding,
    posts,
    alerts,
    messages,
    chat,
    course,
    school,
    job,
    friend,
    reports,
    brands,
  },
  state: {
    brands: [],
    products: [],
  },
  actions: {
    UpdateReports({commit},e){
      axios.put(`api/Documents/${e.id}`, e.previous)
    },
    GetReportsGraph({selectedProduct,selectedBrand,toDate}) {
      if (fromDate < toDate) {
        const id = localStorage.getItem("userId");
        axios
          .post(`api/Documents//GetDailyDrinksByCustomerId/`, {
            customerId: JSON.parse(id),
            dateFrom: moment(this.fromDate, "MM-DD-YYYY").format("MM-DD-YYYY"),
            dateTo: moment(this.toDate, "MM-DD-YYYY").format("MM-DD-YYYY"),
            products: this.selectedProduct,
            brand:this.selectedBrand
          })
          .then((response) => {
            this.reportsGraph = response.data;
            const cat = this.reportsGraph.map((val) => {
              return val.amount;
            });
            const ser = this.reportsGraph.map((val) => {
              return val.date;
            });

            const t_amount = cat.reduce(function (acc, val) {
              return acc + val;
            }, 0);
            this.averageDrinks = t_amount / cat.length;
            this.chartOptions = { xaxis: { categories: ser } };
            this.series = [
              {
                name: "amount",
                data: cat,
              },
            ];
          });
      } else {
        this.$bvToast.toast(`From date must be smaller then to date `, {
          title: "Alert",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }
    },
    SetAllBrands({ commit }, e) {
      var id = localStorage.getItem("origId");
      axios.get(`api/brands/Organization/${id}`).then((response) => {
        commit("setAllBrands", response.data);
      });
    },
    CreateBrands({ commit }, e) {
      var id = localStorage.getItem("origId");
      const data = {
        name: e,
        OrganizationId: id,
      };
      console.log("Id", id);
      axios.post(`api/brands/`, data).then(() => {
        axios.get(`api/brands/Organization/${id}`).then((response) => {
          
          commit("setAllBrands", response.data);
        });
      });
    },
    EditBrand({ commit }, e) {
      var id = localStorage.getItem("origId");
      const data = {
        id: e.id,
        name: e.name,
        OrganizationId: id,
      };
      axios.put(`api/brands/${e.id}`, data).then(() => {
        axios.get(`api/brands/Organization/${id}`).then((response) => {
          
          commit("setAllBrands", response.data);
        });
      });
    },
    DeleteBrands({ commit }, e) {
      var id = localStorage.getItem("origId");
      const data = {
        id: e.id,
        name: e.name,
        OrganizationId: id,
      };
      axios.delete(`api/brands/${e.id}`, data).then(()=>{
        axios.get(`api/brands/`).then((response) => {
          
          commit("setAllBrands", response.data);
        });
      });
    },
    SetAllProducts({ commit }, e) {
      var id = localStorage.getItem("origId");
      axios.get(`api/products/Organization/${id}`).then((response) => {
        commit("setAllProducts", response.data);
      });
    },
    CreateProducts({ commit }, e) {
      var id = localStorage.getItem("origId");
      const data = {
        name: e,
        OrganizationId: id,
      };
      axios.post(`api/products/`, data).then(() => {
        axios.get(`api/products/Organization/${id}`).then((response) => {
          commit("setAllProducts", response.data);
        });
      });
    },
    EditProducts({ commit }, e) {
      var id = localStorage.getItem("origId");
      const data = {
        id: e.id,
        name: e.name,
        OrganizationId: id,
      };
      console.log("Id", e);
      axios.put(`api/products/${e.id}`, data).then(()=>{
        axios.get(`api/products/Organization/${id}`).then((response) => {
          commit("setAllProducts", response.data);
        });
      });
    },
    DeleteProducts({ commit }, e) {
      var id = localStorage.getItem("origId");
      const data = {
        id: e.id,
        name: e.name,
        OrganizationId: id,
      };
      axios.delete(`api/products/${e.id}`, data).then(()=>{
        axios.get(`api/products/Organization/${id}`).then((response) => {
          
          commit("setAllProducts", response.data);
        });
      });
    },
  },
  getters: {
    GetAllBrands(state) {
      return state.brands;
    },
    GetAllProducts(state) {
      return state.products;
    },
  },

  mutations: {
    setAllBrands(state, response) {
      state.brands = response;
    },
    setAllProducts(state, response) {
      state.products = response;
    },
  },
  strict: debug,
});
